.container {
    display: flex;   
    justify-content: center;
    align-items: center;    
  }

  .containerBlack {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3); 
  }
  
  .element {
    background: rgb(255, 255, 255);
    min-height: 100px;
    min-width: 300px;
    margin: 5rem;
    padding: 5rem;
    border-radius: 1rem;
    box-shadow: 1px 2px 2px 2px ;
    position: fixed;
    top: 5rem;
    display: flex; 
    flex-direction: column;  
    justify-content: center;
    align-items: center;  
  }

  .buttonSend{
    width: 10rem;
    height: 4rem;
    color: white;
    border-radius: 5rem;
    border-color: #95a2e2;
    border-style: inset;
    background-color: #6D80E1;
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }