.container{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}

.boy{
    width: 10rem;
}
.balloon{
    border-radius: 1rem 1rem 1rem 0rem;
    border: 0.1rem solid black;
    padding: 1rem;
    vertical-align:super;
    box-shadow: 0px 0.25rem 0.35rem rgba(0, 0, 0, 0.25);
}

.buttonSend{
    width: 10rem;
    height: 4rem;
    color: white;
    border-radius: 5rem;
    border-color: #95a2e2;
    border-style: inset;
    background-color: #6D80E1;
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    
}