.contenedor{
    background: rgba(65, 84, 179, 0.05);
    border: 0.20em solid rgba(65, 84, 179, 0.3);
    display: inline-flex;
    margin-bottom:5em;
    border-radius: 1em;
}
.contenedorPalabras{
    background: #4154B3;
    border: 0.25em solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    position:fixed;
    bottom: 0px; 
    left:0px;
    width: 100%;
    min-height: 3em;  
    display: inline-block;
    gap:5em;
}

.texto{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    line-height:2em;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.06em;
    display:inline-flex;
}

.textoDividido{
    margin-right: 0.5em;
}

.palabras{
    background: #FFFFFF;
    border: 0.12em solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    border-radius: 3.13em;
    text-align: center;
    align-items: center;
    min-height: 2em;
    size:5;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    width: 7em;
    line-height: 1.5em;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    padding: 0.2em;
    position:relative;
    display:inline-flex;
}

.palabrasIni{
    background: #FFFFFF;
    border: 0.12em solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    border-top-left-radius: 3.13em;
    border-bottom-left-radius: 3.13em;
    text-align: center;
    align-items: center;
    min-height: 2em;
    size:5;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    width: 7em;
    line-height: 1.5em;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    padding: 0.2em;
    position:relative;
    display:inline-flex;
}

.palabrasMid{
    background: #FFFFFF;
    border: 0.12em solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    border-radius: 0em;
    text-align: center;
    align-items: center;
    min-height: 2em;
    size:5;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    width: 7em;
    line-height: 1.5em;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    padding: 0.2em;
    position:relative;
    display:inline-flex;
}

.palabrasFin{
    background: #FFFFFF;
    border: 0.12em solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    border-top-right-radius: 3.13em;
    border-bottom-right-radius: 3.13em;
    text-align: center;
    align-items: center;
    min-height: 2em;
    size:5;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    width: 7em;
    line-height: 1.5em;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    padding: 0.2em;
    position:relative;
    display:inline-flex;
}

.movibles{
    background: #FFFFFF;
    border: 0.12em solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    border-radius: 3.13em;
    text-align: center;
    align-items: center;
    min-height: 2em;
    min-width: 3em;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.06em;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    padding: 0.2em;
    position:relative;
    display:inline-flex;
}

.palabraClave{
    width: 100%;
    font-style: italic;
    font-weight: 900;
}
.palabraSalto{
    color: red;
    margin-left: auto;
    width: 100%;
    height: 0px;
    line-height: 10%;

}