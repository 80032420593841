.question{

font-style: bold;
font-weight: 700;
font-size: 22px;
line-height: 37px;
/* identical to box height */

padding-top: 0,8em;
padding-bottom: 0,8em;
align-items: center;
text-align: center;
text-transform: capitalize;

color: #000000;
width: 100%;

}
.answer{

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    
    display: flex;
    flex-direction: column;

    background: rgba(65, 84, 179, 0.05);
    border: 4px solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    border-radius: 20px;
    padding-top: 1em;
}