.contenedor{
    display: flex;
    justify-content: center;
    background: rgba(65, 84, 179, 0.05);
    border: 0.15em solid rgba(65, 84, 179, 0.3);
    border-radius: 1em;
    width: 25em;
}
.pregunta{
    width: 9.5em;
    padding: 0.31em;
    background: rgba(65, 84, 179, 0.05);
    border: 0.15em solid rgba(169, 171, 180, 0.3);
    min-height: 3.5em;
    border-radius: 1em;

    align-items: center;
    display: flex;
    justify-content: center;
}
.respuesta{
    width: 9.5em;
    padding: 0.2em;
    background: rgba(65, 84, 179, 0.05);
    border: 0.20em solid rgba(65, 84, 179, 0.3);
    min-height: 3.5em;
    border-radius: 1em;
    box-shadow: 0px 0.25em 0.35em rgba(0, 0, 0, 0.25);

    align-items: center;
    display: flex;
    justify-content: center;
}
.flechaDivisora{
    width: 2em;
}

