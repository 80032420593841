.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .contenedorPrincipal{
    background-color: #eeeeee;
    margin-left: 100px;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .flotante{
    position:fixed;
    bottom: 0px;
    right: 0px;
    width: 150px;
    height: 400px;
    border-color: black;
    background-color: white;
    box-shadow: #342828 2px 2px 2px 2px; 
    font-size: 20px;
  }

  .buttonFlotante{
    position:fixed;
    bottom: 0px;
    right: 0px;
    width: 60px;
    height: 30px;
    border-color: black;
    background-color: white;
    box-shadow: #282c34 2px 2px 2px 2px;
    z-index: 1;
  }

  .balloonTitle{
    background: #6D80E1;
    border: 0.15em solid rgba(65, 84, 179, 0.3);
    text-transform: uppercase;
    margin: 0.2em;
    padding: 0.4em;
    border-radius: 1.5em 1.5em 1.5em 0em ;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 1.05em;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: none;
    width: 95%;
}

.clueButton{
  position:relative;
  left:0px;
  top: 0px;
  color:red;
  width:30px;
  height: 30px;
  margin-top:15px;
  border-radius: 50%;
  font-size: 23px;
}