.container {
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    padding: 0.5em;
    flex-wrap: nowrap;
    flex: 1;
    height: 100%;
    max-height: 800px;
}

.buttonSend {
    width: 7rem;
    height: 2.8rem;
    margin-top: 20px;
    color: white;
    border-radius: 10px;
    border: none;
    border-color: #95a2e2;
    background-color: #6D80E1;
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
}

.palabras {
    background: #FFFFFF;
    width: 100%;
    height: 100px;
    resize: none;
    text-wrap: wrap;
    word-wrap: break-word;
    word-break: break-all;
    height: 80px;
    border: 0.12em solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    text-align: center;
    align-items: center;
    size: 5;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5em;
    align-items: center;
    text-align: left;
    text-transform: capitalize;
    padding: 0.2em;

}

.containerTwo {
    overflow-y: scroll;
    flex-wrap: nowrap;
    flex-direction: row;
    background-color: #ffffff;
    padding: 0.5em;
    margin: 0.5em;
}

.filaYo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.filaEl {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.filaEnviar {
    padding: 0.5em;
    width: --webkit-fill-avaliable;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.yo {
    background: #e8f7ed59;
    border-radius: 10px;
    text-wrap: wrap;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 50%;
    border: 0.12em solid rgba(65, 84, 179, 0.3);
    box-sizing: border-box;
    align-items: center;
    min-height: 2em;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.06em;
    line-height: 1.5em;
    align-items: right;
    text-align: right;
    padding: 0.2em;
    margin: 0.1em;
    padding-left: 1em;
    padding-right: 1em;
}

.el {
    background: #FFFFFF;
    border-radius: 10px;
    text-wrap: wrap;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 50%;
    border: 0.12em solid rgba(65, 84, 179, 0.3);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.06em;
    line-height: 1.5em;
    align-items: left;
    text-align: left;
    padding: 0.2em;
    margin: 0.1em;
    padding-left: 1em;
    padding-right: 1em;
}