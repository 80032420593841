.container{
    background: rgba(65, 84, 179, 0.05);
    border: 0.20em solid rgba(65, 84, 179, 0.3);
    display: inline-flex;
    margin-bottom:5em;
    border-radius: 1em;
    padding:1em;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.06em;
}